
@import url('https://fonts.googleapis.com/css?family=Archivo');

:root {
  --primary300: #B81A8E;
  --primary400: #B81A8E;
  --primary500: #B81A8E;
  --primary700:#5F0447;
  --textdark:#181C32;
  --textlight:#ffffff;
  --bglight:#f5f8fa;
  --bglight2:#ffffff;
  --inpborder : #E4E6EF;
  --inpbg:#f5f8fa;
  --inpbgfocus:#eef3f7;
  --sidebarbg:#1e1e2d;
  --sidebarcolor:#ffffff;
  --sidebaractivebg:#2A2A3C;
  --sidebarscrollcolor:#eef3f7;
  --black200: #e6e7e8;
  --black300: #81898d;
  --black400: #6c7477;
  --black500: #364349;
  --black600: #04141c;
  --success: #22bb33;
  --danger:#bc1700;
  --warning:#ffc107
}

.root {
  padding: 0px;
}
::-webkit-scrollbar {
  background-color: var(--light);
  width: 7px;
  height: 7px;
}
svg {
  fill: currentColor;
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary500) ;
  border: 0.25em /* 4px / 16px */ solid whites;
  border-radius: 1.25em /* 20px / 16px */;
}
body {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--textdark);
  background-color: var(--bglight);
  overflow: hidden;
  font-family: "Archivo", sans-serif;
}

h1 {
  font-size: 40px;
  line-height: 47.4px;
  font-weight: 700;
}
h2 {
  font-size: 34px;
  line-height: 47.4px;
  font-weight:500;
}
h3 {
  font-size: 34px;
  line-height: 33.18px;
  font-weight: 500
}
h4 {
  font-size: 22px;
  line-height: 33.18px;
  font-weight:600;
}
.subtitle {
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 500;
}
.title {
  font-weight: 600;
  font-size: 20 px
}
.legend {
  font-size: 14px;
  line-height: 16.8px;
}
.currentR {
  font-size: 18px;
  line-height: 21.6px;
}
.currentM {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 500;
}
.button {
  border-radius: 8px !important;
  padding: 12px 10px 12px 10px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  box-shadow: unset !important;
  line-height: 21.33px;
  min-width: 120px;
}

.btn-primary,.btn-primary:focus {
  background-color: var(--primary500);
  border-width: 0px;
  width: 100%;
  color:var(--bglight2)
}
.btnsmall{
  font-size: 14px;
  width:80px !important;
  min-width: 80px !important;
  height:35px;
  margin-left: -6px;

}

.btn-primary:hover {
  background-color: var(--primary400);
  opacity: 0.8;
}

.btn-primary:disabled ,.btn-primary-disabled{
  background-color: var(--black200) ;
  color: var(--black300) ;
}
.btn-secondary,.btn-secondary:focus {
  color:var(--primary500);
  background-color: var(--bglight2);
  border: 1px solid var(--primary500);
  width: 100%;
}

.btn-secondary:hover {
  border-color: var(--primary400);
  color:var(--primary500);
  background-color: var(--bglight2);
  opacity: 0.8;
}

.btn-secondary:disabled,.btn-secondary-disabled {
  background-color: var(--black200);
  color: var(--black300);
  border-color:var(--black300);
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: var(--blue600);
}

.input-container {
  margin-bottom: 12px !important;
}

.input-group {
  z-index: 0 !important;
}
.badge{
  color: var(--black600);
  border-radius: 8px;
  min-width: 70px;
  border: 1px solid;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 600 !important;
}
.badge-success{
   color:var(--success) !important;
   border-color: var(--success) !important;
}
.badge-danger{
  color:var(--danger) !important;
  border-color: var(--danger) !important;
}
.badge-warning{
  color:var(--warning) !important;
  border-color:var(--warning)!important;
}
.badge-violet{
  color:#7239ea !important;
  border-color:#7239ea!important;
}
.badge-blue{
  color:#0d6efd !important;
  border-color:#0d6efd!important;
}

.input-group-icon {
  background-color: var(--inpbg);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color:  var(--inpborder);
  border-left-width: 0px;
  
}
.input-group-icon .iconBtn {
  border-width: 0px;
  padding: 0px;
  background-color: var(--inpbg);
}
.input-group-icon .iconBtn:hover {
  opacity: 0.5;
}

.form-text {
  color: var(--black300);
  font-size: 14px;
  margin-top: 4px;
}
.form-label {
  font-size: 14px;
  line-height: 16.8px;
  color: var(--black600);
}

.form-control,
.form-select {
  border-radius: 0.475rem;
  font-size: 14px;
  padding: 0.775rem 1rem;
  line-height: 1.5;
  line-height: 16.8px;
  color: var(--black600);
  background-color: var(--inpbg) !important;
  border-width: 1px;
  border-color: var(--inpborder) !important;
  min-height: 42px !important;
}
.form-date{
  height: 42px;
}
.form-control:hover,
.form-select:hover,
.form-control:hover ~ .input-group-icon {
  border-width: 0.5px;
  border-color: var(--primary400) !important;
  background-color: var(--inpbgfocus);
}
.form-control:focus,
.form-select:focus,
.form-control:focus ~ .input-group-icon {
  border-width: 1px;
  border-color: var(--primary500) !important;
  box-shadow: unset !important;
  outline: 0 none !important;
  outline-offset: 0;
  background-color: var(--inpbgfocus);
}
.form-control:-webkit-autofill  {
  -webkit-background-clip: text;
    box-shadow: inset 0 0 20px 20px var(--inpbg) !important;
}
.form-control:-webkit-autofill:hover ,
.formfocus,
.form-control:-webkit-autofill:focus ,
.form-control:-webkit-autofill:active{
  -webkit-background-clip: text;
  box-shadow: inset 0 0 20px 20px var(--inpbgfocus) !important;
}
.form-control-invalid,
.form-select-invalid,
.form-control-invalid ~ .input-group-icon {
  border-color: var(--danger) !important;
}

.form-control:disabled  {
  color:gray !important;
  border-color: var(--inpborder) !important;
}
/* .form-control-valid,
.form-select-valid,
.form-control-valid ~ .input-group-icon {
} */
.form-control::placeholder {
  color: var(--black300);
}
.form-control-icon {
  border-right-width: 0px !important;
} 


.Toastify__toast--success{
  color:var(--success) !important;
}
.Toastify__toast--error{
  color:var(--danger) !important;
}
.form-error-msg {
  color: var(--danger);;
  font-size: 14px;
  margin-top: 4px;
}

.close-icon {
  position: absolute;
  pointer-events: fill;
}
.perm{
  font-size: 17px;
  font-weight: 600;
}
.page{
  overflow: auto;
  position: relative;
  height: 100dvh;
  margin: 0px;
  padding: 24px ;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.center{
  display: flex; 
  justify-content: center;
  align-items: center;
  flex-grow: 1
}

@media (max-width: 768px) {
  .page{
    height: calc(100dvh - 70px);
    padding: 16px;
  }
  
}
.card{
  background-color: var(--bglight2);
  border-radius: 8px;
  border-width: 0px !important;
}
.bgprimary{
  background-color:  var(--primary500);
  color:var(--bglight2)
}
.accordion-button:focus{
  border-color: var(--primary500) !important;
  box-shadow: unset !important;
}
.accordion-button:not(.collapsed){
  color:var(--primary500) !important ;
  background-color: white !important;
}
.flexcolumn{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}