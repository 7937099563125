.formlayout{
    width: 100%;
    overflow: auto !important;
    display: "flex";
    flex-grow: 1;
  
}
.formlayout .formcard{
  width: 100%;
  border-width: 0px;
  overflow: auto !important;
  height: 100%;
}
.formlayout .formcontent{
  overflow: auto;
   display: flex;
   flex-grow: 1;
    width: 100%;
     flex-direction: column
}
.formlayout .formbody{
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  overflow: auto;
  flex-grow: 1;
}
.formlayout .formbtn{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-top: 1px solid whitesmoke;
  padding-top: 20px;
  margin-top: 10px;
}
.formlayout .formcontent>h4{
  margin:30px 30px 0px 30px;
}
.formlayout .formbtn{
  padding:30px;
}
.formlayout .formbtn >.add{
  max-width: 150px;
  float: right;
}
@media (max-width: 768px) {
  .formlayout .formbody{
    padding-left: 16px;
    padding-right: 16px;
  }
  .formlayout .formcontent>h4{
      margin:16px 16px 0px 16px;
    }
    .formlayout .formbtn{
      padding: 16px;
   } 
}