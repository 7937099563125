.datetime{
  width: 100%;
 
}
.datetime .MuiFormControl-root{
  width: 100% !important;
}
.datetime .MuiInputBase-root{
  background-color: var(--inpbg) !important;
  border-width: 1px;
  font-size: 14px;
  border-color: var(--inpborder) !important;
  height: 42px !important;
  border-radius: 0.475rem;
}
.datetime .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--inpborder) ;
}
.datetime .dateerror > .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--danger);
}
.datetime .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
border-width: 0.5px;
  border-color: var(--primary500) ;
}
.datetime .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--primary500);
}
.datetime .MuiSvgIcon-root{
  color:var(--primary500)
}