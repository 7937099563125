.apptable{
  --bgtable:white;
  --bgrowpair:var(--light);
  --bgrowimpair:var(--light);
  --bgrowfocus:var(--light);
  --bgheader:var(--light);
  --colorheader:var(--textdark);
  --headericoncolor:var(--primary500);
  --paginationcolor:var(--primary500);
  --circularprogress:var(--primary500);
  --bgindicator:#fcf1ef;
  --colorindicator:var(--primary500);
  --scrollcolor:var(--primary500);
  --linearprogressactive:var(--primary500);
  --linearprogressinactive:var(--primary500);
}
.MuiPopover-root {
      z-index: 6 !important;
}
.modalContent{
  width: 400px;
  overflow: hidden;
}
@media (max-width: 468px) {
   .modalContent{
    width:70vw
  }
}
.apptable {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-grow: 1;
  padding-top: 16px;
  background-color: var(--bgtable);
  flex-direction: column;
  overflow-x: hidden;
}

.cancel{
  margin-top: 10px;
}

.apptable ::-webkit-scrollbar {
  background-color: var(--bgtable) !important;
  width: 7px;
  height: 7px;
}
.apptable ::-webkit-scrollbar-thumb {
  background-color: var(--scrollcolor) !important;
  border-radius: 1.25em;
}
.apptable .css-1atu56z-MuiPaper-root , .tabletop + div ,.tabletop + div >.MuiTableContainer-root{
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.apptable .css-uhr8s1-MuiTableContainer-root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.apptable th {
  background-color: var(--bgheader);
  color: var(--colorheader);
  padding-top: 0px !important;
  padding-bottom: 15px !important;
}

.apptable tbody > .rowpair {
  background-color: var(--bgrowpair) !important;
  border-width: 0;
}
.apptable tbody>.rowimpair{
  background-color: var(--bgrowimpair) !important;
  border-width: 0;
}
.apptable tbody>.MuiTableRow-root:hover {
  background-color: var(--bgrowfocus) !important;
}

.apptable .css-1atu56z-MuiPaper-root {
  z-index: 5!important;
}
.apptable .css-1atu56z-MuiPaper-root:has(.css-nji4p8){
  z-index: 20 !important;
}
.apptable .MuiCircularProgress-svg {
  color: var(--circularprogress) !important;
}
.apptable .MuiLinearProgress-root{
  background-color: var(--linearprogressactive);
}
.apptable .MuiLinearProgress-bar{
  background-color: var(--linearprogressinactive);
}
.apptable .MuiSvgIcon-root,
.MuiPaginationItem-root {
  color: var(--paginationcolor) !important;
}
.apptable .Mui-TableHeadCell-Content .MuiSvgIcon-root {
  color: var(--headericoncolor)!important;
}
.apptable .addbtn {
  height: 40px;
}
.apptable .MuiTableContainer-root[aria-busy="true"] {
  overflow: hidden;
}
.apptable .addsm{
  display: none;
}
@media (max-width: 468px) {
  .apptable .MuiPaginationItem-page,
  .MuiPaginationItem-ellipsis {
    display: none;
  };

  .apptable .disabledbtn{
    opacity: 0.2 !important;
  }

  .apptable .addsm{
    display: block;
  }
  .apptable .MuiInputBase-formControl{
    max-width: 50dvw;
  }
  .apptable .MuiFormLabel-root[for="mrt-rows-per-page"]{
    display: none;
  }
  .apptable .MuiButtonBase-root[aria-label="Changer la densité"]{
    display: none;
  }
  .apptable .addbtn{
    display: none;
  }
  .apptable .subtitle{
    font-size: 18px;
  }
  .apptable .indicator{
    font-size: 18px;
  }
  .apptable .tabletop{
    margin-bottom: 0px;
  }

}
.apptable .action:hover{
  opacity: 0.4;
}

.apptable .css-v9zqoq-MuiCollapse-root{
display: none;
}
.apptable .indicator{
background-color: var(--bgindicator);
padding-left: 10px;
padding-right: 10px;
padding-top: 5px;
padding-bottom: 5px;
border-radius: 7px;
color:var(--colorindicator);
margin-inline-start: 10px;
}
.apptable .tabletop{
padding-left: 10px;
padding-right: 10px;
margin-bottom: 16px;
}
/*
.MuiTableRow-head{
}
.MuiPaper-root{
  height: 100%;
  overflow: auto;
}
tbody>.MuiTableRow-root {

}

tbody>.Mui-selected {
}
 .MuiTableContainer-root {
}


.MuiTablePagination-root {
   
} */
/* table card style */