.login{
    background: linear-gradient(var(--primary500),var(--primary700)); 
    height: 100dvh;
    padding: 16px;
    overflow:auto;
    
  }
  .login .logo{
    width: 95%;
    min-width: 200px;
    max-width: 500px;
}
@media (max-width: 768px) {
    .login .logo{ 
    max-width: 190px;
   }
}
.login .loginform{
    width: 100%;
    max-width: 600px;
    min-height: 400px;
}