
.sidebar {
  color: var(--sidebarcolor);
  background-color: var(--sidebarbg);
   z-index:6;
  overflow: auto !important;
  
}
.togglebar,
.btn-close {
  display: none;
}
.usesm{
  display: none;
}
.userbg{
   align-self: center; 
   margin-top: 20px;
}

@media (max-width: 768px) {
  .btn-close {
    display: block;
    color: white;
    z-index: 4;
    float: right;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .collapse{
    display: none !important;
  }
  .usesm{
    display: block;
  }
  .username{
    display: none;
  }
  .userbg{
    display: none;
  }
  .togglebar {
    display: flex;
    background-color: var(--bglight2);
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sidecontent {
    display: none;
  }
  .offcanvas-body{
    padding: 0px !important;
    margin: 0px !important;
  }
  .userlink{
    color: #3F4254; 
    font-size: 14px;
  }
  .divider{
    background-color: #eff2f5;
  }
}
.sidebar ::-webkit-scrollbar {
  background-color: var(--sidebarbg) !important;
  width: 7px;
  height: 7px;
}
.sidebar ::-webkit-scrollbar-thumb {
  background-color: var(--sidebarbg) !important;
  border: 0.25em solid var(--sidebarbg);
  border-radius: 1.25em;
}
.sidebar div {
  background-color: var(--sidebarbg);
}
.sidebar .container {
  height: 100dvh;
  padding: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
}
.sidebar .sidebartop{
  display: flex;
  flex-direction: row; 
  width: 100%; 
  justify-content: space-between
}
.sidebar .menu {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-grow: 1;
}
.sidebar .menu ul {
  width: 100%;
}
.sidebar .submenu{
  font-size: 13px;
}
.sidebar .submenu-single > .ps-menu-button >.ps-submenu-expand-icon {
  display: none;
}
.sidebar .submenu > .ps-menu-button{
  opacity: 0.6;
  height: 40px;
}
.sidebar .submenu > .ps-menu-button:hover {
  background-color: var(--sidebarbg) !important ;
  width: 100%;
}
.sidebar .submenu-inactive > .ps-menu-button:hover {
  opacity: 0.85;
}

.sidebar .submenu-active > .ps-menu-button {
  background-color: var(--sidebarbg);
  color :var(--bglight2);
  opacity: 1;
}
.sidebar .menuitem {
  font-size: 13px;
}

.sidebar .ps-menu{
  font-size: 13px !important;
}
.sidebar .menuitem >.ps-menu-button{
  background-color: #2A2A3C;
  height: 37px;
  margin-left: 30px;
  margin-right: 22px;
  border-radius: 4px;
  opacity: 0.6;
}

.sidebar .menuitem > .ps-menu-button:hover {
  background-color: #2A2A3C;
  opacity: 1;
}
.sidebar .menuitem-active > .ps-menu-button {
  opacity: 1;
}

.sidebar .icon {
  width: 20px;
  color: #C5C5D8;
}
.sidebar .collapse{
    position: absolute;
    right: 0px;
    background-color: var(--bglight2);
    width: 23px;
    height: 26px;
    padding: 3px;
    display: flex;
    align-items: center;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    justify-content: center;
}
.sidebar .logo {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left:10px;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba(197, 216, 216,0.2);
}
.sidebar .logo > img {
  width: 100px;
}

.sidebar .logout {
  margin-top: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--light);
  border-width: 0px;
  border: 2px solid var(--light);
}

.sidebar .logout:focus,
.logout:hover {
  background-color: rgba(255, 255, 255, 0.2);
}